import { Box, Grid, Typography } from '@mui/material'
import { Strapi__Component_Partners_Advantage, Strapi__Component_Partners_Step } from 'gatsby-graphql'
import React from 'react'
import { theme } from 'src/theme/ThemeProvider'
import Image from '@/components/image'
import RichText from '@/components/elements/rich-text'
import StepsSection from './steps'

interface IAdvantagesSection {
  title: string
  advantages: Array<Strapi__Component_Partners_Advantage>
  steps: Array<Strapi__Component_Partners_Step>
}
const AdvantagesSection = (props: IAdvantagesSection) => {
  return (
    <Grid
      container
      sx={{
        marginBottom: '60px',
        marginTop: { md: '36px', xs: '45px' },
      }}
    >
      <Grid item xs={12}>
        <Typography sx={{ fontSize: '40px', textAlign: 'center', fontWeight: 'bold', padding: '20px' }}>
          {props?.title}
        </Typography>
      </Grid>
      <StepsSection steps={props.steps} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: { md: '100%', xs: '80%' },
          flexDirection: { md: 'row', xs: 'column' },
          m: 'auto',
          position: 'relative',
          height: 'fit-content',
        }}
      >
        {props?.advantages?.map((advantage) => (
          <Box
            sx={{
              maxWidth: '100%',
              paddingY: { md: '0px', xs: '30px' },
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                width: { lg: '261px', md: '214px', xs: '261px' },
                height: '100%',
                minHeight: { lg: '320px', md: '450px' },
                padding: '20px',
                backgroundColor: theme.palette.background.paper,
                position: 'relative',
                color: 'black',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                zIndex: -1,
                boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.4)',
                py: 4,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: { xs: '75%', sm: '90%' },
                }}
              >
                <Image media={advantage.Image} />
              </Box>
              <Typography
                sx={{
                  color: theme.palette.primary.dark,
                  fontSize: '30px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {advantage.Title}
              </Typography>

              <div>
                {/* <RichText
                    markdown={advantage?.Paragraph1?.data?.Paragraph1}
                    sx={{
                      color: theme.palette.primary.dark,
                      fontSize: '24px',
                      textAlign: 'center',
                    }}
                  /> */}

                <RichText
                  markdown={advantage?.Paragraph?.data?.Paragraph}
                  sx={{
                    color: theme.palette.primary.dark,
                    fontSize: '16px',
                    textAlign: 'center',
                    fontWeight: 400,
                  }}
                />
              </div>
            </Box>
          </Box>
        ))}
          <Box
            sx={{
              bgcolor: '#F3504F',
              width: '150vw',
              height: {md:'80%', xs: "77%"},
              position: 'absolute',
              zIndex: -9001,
              top: {md:'13%',xs: "12%"},
              left: '-38%',
            }}
          ></Box>
      </Box>
    </Grid>
  )
}
export default AdvantagesSection
