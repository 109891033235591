import React, { useEffect, useState } from 'react'
import Avatar from '@/components/elements/avatar'
import { Box, Link, Typography, useMediaQuery } from '@mui/material'
import { Strapi__Component_Partners_Avatar } from 'gatsby-graphql'
import AvatarSlider from '@/components/elements/avatar-silder'
import { theme } from 'src/theme/ThemeProvider'
import { Oval } from 'react-loader-spinner'

interface NetworksSectionProps {
  title: string
  avatars: Array<Strapi__Component_Partners_Avatar>
}

const NetworksSection = (props: NetworksSectionProps) => {
  const isMobile = useMediaQuery('(max-width: 925px)')
  const [mobileLoading, setMobileLoading] = useState(true)

  useEffect(() => {
    setTimeout(
      () => {
        setMobileLoading(false)
      },
      window.innerWidth < 769 ? 3000 : 1000,
    )
  }, [])

  return (
    <>
      <Box sx={{ width: { sm: '90%', xs: '100%' }, m: 'auto', py: { md: '40px', sm: 5, xs: 6 } }}>
        <Typography sx={{ textAlign: 'center', fontSize: '30px', fontWeight: 700 }}>{props.title}</Typography>
        {mobileLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              paddingTop: { md: 8, xs: 4 },
              paddingBottom: { md: 16, xs: 8 },
            }}
          >
            <Oval color="#ffffff" />
          </Box>
        ) : !isMobile ? (
          <Box
            sx={{
              display: 'flex',
              gap: 12,
              justifyContent: 'center',
              width: '100%',
              height: '168px',
              mx: 'auto',
              mt: '28px',
              px: '28px',
              scrollbarWidth: 'none',
              textAlign: 'center',
            }}
          >
            {props.avatars.map((item) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                {item.isHyperLink ? (
                  <>
                    <Link
                      href={item?.Url}
                      target={item?.NewTab ? '_blank' : '_self'}
                      sx={{
                        textDecoration: 'none',
                        color: theme.palette.text.primary,
                      }}
                    >
                      <Avatar image={item?.Image} />
                    </Link>
                    <Link
                      href={item?.Url}
                      target={item?.NewTab ? '_blank' : '_self'}
                      sx={{
                        textDecoration: 'none',
                        color: theme.palette.text.primary,
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontSize: '17px',
                          fontWeight: 700,
                          color: theme.palette.text.primary,
                        }}
                      >
                        {item.Title}
                      </Typography>
                    </Link>
                  </>
                ) : (
                  <>
                    <Avatar image={item?.Image} />
                    <Typography
                      sx={{ textAlign: 'center', fontSize: '17px', fontWeight: 700, color: theme.palette.text.primary }}
                    >
                      {item.Title}
                    </Typography>
                  </>
                )}
              </Box>
            ))}
          </Box>
        ) : (
          <AvatarSlider avatars={props.avatars} />
        )}
      </Box>
    </>
  )
}

export default NetworksSection
