import React from 'react'
import { Box } from '@mui/material'
import Image from '@/components/image'
import { Strapi__Media } from 'gatsby-graphql'

interface AvatarProps {
  image: Strapi__Media
}

const Avatar = (props: AvatarProps) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 20px 40px 0px rgba(0, 0, 0, 0.40)',
        borderRadius: '100%',
        border: '4px solid rgba(255, 255, 255, 0.50)',
        width: { md: '104px', xs: '64px' },
        height: { md: '104px', xs: '64px' },
        overflow: 'hidden',
      }}
    >
      <Image media={props.image} />
    </Box>
  )
}

export default Avatar
