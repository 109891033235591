import '../../styles/swiper-slider.css'
import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import { Strapi__Component_Partners_Avatar } from 'gatsby-graphql'
import Avatar from './avatar'
import { Swiper, SwiperSlide } from 'swiper/react'
import { theme } from 'src/theme/ThemeProvider'
import { Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

interface AvatarSliderProps {
  avatars: Array<Strapi__Component_Partners_Avatar>
}

const AvatarSlider = (props: AvatarSliderProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: '100%',
        margin: 'auto',
        alignItems: 'center',
        height: '220px',
        mt: 6,
      }}
    >
      <Swiper spaceBetween={30} slidesPerView={3} pagination={{ clickable: true }} modules={[Pagination]}>
        {props.avatars?.map((slide, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                width: '100% !important',
                display: 'flex !important',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center !important',
                height: '200px',
              }}
            >
              {slide.isHyperLink ? (
                <>
                  <Link
                    href={slide?.Url}
                    sx={{
                      textDecoration: 'none',
                      color: theme.palette.text.primary,
                    }}
                    target={slide?.NewTab ? '_blank' : '_self'}
                  >
                    <Avatar image={slide.Image} />
                  </Link>
                  <Link
                    href={slide?.Url}
                    sx={{
                      textDecoration: 'none',
                      color: theme.palette.text.primary,
                    }}
                    target={slide?.NewTab ? '_blank' : '_self'}
                  >
                    <Typography sx={{ textAlign: 'center', fontSize: '17px', fontWeight: 700 }}>
                      {slide.Title}
                    </Typography>
                  </Link>
                </>
              ) : (
                <>
                  <Avatar image={slide.Image} />
                  <Typography sx={{ textAlign: 'center', fontSize: '17px', fontWeight: 700 }}>{slide.Title}</Typography>
                </>
              )}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default AvatarSlider
